@import '../../../../globalStyles/helpers/variables.scss';
* {
    font-family: 'Roboto';  
    margin: 0;
    padding: 0;
}

#hero {
    background-color: #f5f5f5;

    .search-container{
        width: 100%;
        background-color: $secondaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;
    }

    .swiper-container {
        display: flex;
        width: 100%;
    }

    .img-container {
        position: relative;
        height: 100vh;
        width: 100%;
    }

    .hero1-img {
        object-fit: cover;
        height: 100vh;
        width: 100vw;
    }

    .img-card {
        position: absolute;
        display: flex;
        align-items: center;
        width: 60%;
        height: 36%;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        background-color: red;
        border-radius: 25px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        top: 28%;
        left: 0;
        right: 0;
        padding: 80px 90px;
        p {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 28px;
            font-weight: 500;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@media (max-width: 1100px) {
    #hero {
        .img-card {
            p {
                font-size: 28px;
            }
        }
    }
}

@media (max-width: 815px) {
    #hero {
        .img-card {
            p {
                font-size: 26px;
            }
        }
    }
}

@media (max-width: 770px) {
    #hero {
        .img-container {
            height: 50vh;

            .hero1-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .img-card {
            width: 80%;
            height: 60%;
            padding: 50px 50px;
            margin-top: -2em;

            p {
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 675px) {
    #hero {
        .img-card {
            padding: 50px 50px;

            p {
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 525px) {
    #hero {
        .img-card {
            width: 80%;
            height: 70%;
            padding: 20px 20px;
            margin-top: -3em;

            p {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 380px) {
    #hero {
        .img-card {
            width: 70%;
            height: 36%;
            margin-top: 20px;

            p {
                font-size: 20px;
            }
        }
    }
}

// @media (max-width: 655px) {
//     #hero {
//         input[type='text'] {
//             padding: 8px 20px;
//             width: 65%;
//         }
//     }
// }

// @media (max-width: 425px) {
//     #hero {
//         input[type='text'] {
//             padding: 8px 20px;
//             width: 65%;
//         }
//     }
// }

@media (max-width: 375px) {
    #hero {
        .img-card {
            padding: 10px 10px;
            width: 90%;
            height: auto;

            p {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 325px) {
    #hero {
        .img-card {
            width: 70%;
            height: 36%;
            margin-top: 20px;

            p {
                font-size: 16px;
            }
        }
    }
}
